import React, { useContext, useEffect } from "react"

import { graphql } from "gatsby"
import { useIntl } from "react-intl"

import withTemplate, { TemplateDefaultPropTypes } from "../withTemplate"
import SEO from "~/components/Seo/Seo"
import {
  Body,
  Button,
  Col,
  Container,
  PrismicTextarea,
  Row,
} from "~/components/ui"
import { CreatorsConditionsContext } from "~/context/CreatorsConditionsContext"
import { PrismicStructuredText } from "~/models/PrismicTypes"
import { useUpdateDataMutation } from "~/state/api/profile"

import css from "./legal-notices-creators.module.scss"

interface PropTypes extends TemplateDefaultPropTypes {
  data: LegalNoticesCreatorsQuery
}

function LegalNoticesCreatorsPage(props: PropTypes) {
  const intl = useIntl()

  const text = props.data.data.data.conditions

  const { creatorsConditionsIsAccepted, isCreator, refetch, getQuerySuccess } =
    useContext(CreatorsConditionsContext)

  const [
    acceptCreatorsConditions,
    { isSuccess: patchQuerySuccess, isError, isLoading },
  ] = useUpdateDataMutation()

  const handleClick = () => {
    acceptCreatorsConditions({
      ["creators_t_c_accepted"]: true,
    })
  }
  useEffect(() => {
    if (refetch) refetch()
  }, [patchQuerySuccess])
  return (
    <div className={css.legalNotices}>
      <SEO
        title={intl.formatMessage({ id: "path:legal-notices-creators" })}
        location={props.location}
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <Container>
        <Row>
          <Col offset={[0, 1]} width={[12, 10]}>
            <PrismicTextarea text={text} />
          </Col>
        </Row>
      </Container>
      {isCreator && !creatorsConditionsIsAccepted && getQuerySuccess && (
        <div className={css.bottomBanner}>
          {isError ? (
            <Body variant="body1" semiBold>
              {intl.formatMessage({
                id: "profile/text:cancel_subscription_error",
              })}
            </Body>
          ) : (
            <Button
              variant="primary"
              color="original"
              onClick={() => handleClick()}
              disabled={patchQuerySuccess || isLoading}
            >
              {intl.formatMessage({
                id: "creatorsconditions/action:accept-button",
              })}
            </Button>
          )}
        </div>
      )}
    </div>
  )
  //
}

interface LegalNoticesCreatorsQuery {
  data: {
    data: {
      conditions: PrismicStructuredText
    }
  }
}

export const query = graphql`
  query LegalNoticesCreatorsPage($lang: String) {
    data: prismicLegals(
      lang: { eq: $lang }
      data: { type: { eq: "tos-creators" } }
    ) {
      data {
        conditions {
          ...PrismicStructuredText
        }
      }
    }
  }
`

export default withTemplate(LegalNoticesCreatorsPage)
